<template>
    <!-- <choose-list v-bind="$attrs" :success="successCallback" :type="'other'" :priex-route="priexRoute"></choose-list> -->
    <choose-list v-bind="$attrs"  :type="'other'" :otherType="'other'" :priex-route="priexRoute"></choose-list>

</template>

<script>
import chooseList from "@/views/api/teaching/question/chooseList";
export default {
  props:['priexRoute'],
  components:{chooseList},
  // methods:{
  //   successCallback(data){
  //     let{other_id,...others} = {...this.$route.query ,...this.$route.params};
  //     let {success} = this.$attrs;
  //     if((data || {}).other_id+'' !== other_id+''){
  //       return this.$handleRoute({...others,other_id:data.other_id} , this.$route.path , true)
  //     }
  //     typeof success === 'function' && success(...arguments);
  //   },
  // },
}
</script>

<style scoped>

</style>